import { createMuiTheme } from "@material-ui/core";


const defaultFont = "'Baloo 2'";
const headingFont = "Lato";
const monospaceFont = "PT Mono";

const commonHeading = {
    fontFamily: headingFont,
    margin: "0",
    padding: "0",
};

const commonCode = {
    fontFamily: monospaceFont,
    fontSize: 14,
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    margin: "1.5em",
    padding: "1em",
};

export const theme = createMuiTheme({
    props: {
        MuiTypography: {
            variantMapping: {
                "body2": "pre",
            },
        },
    },
    typography: {
        fontFamily: defaultFont,
        fontSize: 23,
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    overflowX: "hidden",
                    color: "#444444",
                },
                h1: {
                    ...commonHeading,
                    fontSize: 60,
                },
                h2: {
                    ...commonHeading,
                    fontSize: 39,
                },
                h3: {
                    ...commonHeading,
                    fontSize: 25,
                },
                pre: {
                    ...commonCode,
                    overflow: "scroll",
                },
                a: {
                    color: "#003CA5",
                    textDecoration: "none",
                },
                "a:hover": {
                    textDecoration: "underline",
                },
                blockquote: {
                    backgroundColor: "#EEDC82",
                    paddingLeft: "0.5em",
                    paddingRight: "0.5em",
                    paddingTop: "0.1em",
                    paddingBottom: "0.1em",
                    margin: "1em",
                    color: "#555555",
                },
                hr: {
                    border: "solid #888888 1px",
                },
                ".language-text": {
                    fontFamily: monospaceFont,
                    fontSize: "83%",
                    color: "#BF6F6F",
                },
                "span.token.comment": {
                    color: "#888888",
                },
                "span.token.class-name": {
                    color: "#4682B4",
                },
                "span.token.function": {
                    color: "#4682B4",
                },
                "span.token.keyword": {
                    color: "#B56727",
                },
                "span.token.string": {
                    color: "#76ABDF",
                },
                "span.token.number": {
                    color: "#76ABDF",
                },
            },
        },
        MuiAlert: {
            root: {
                fontSize: 18,
            },
        },
        MuiButton: {
            label: {
                fontSize: 15,
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "rgba(0,0,0,0)",
                border: "none",
            },
            root: {
                boxShadow: "none",
                border: "none",
            },
        },
        MuiCardHeader: {
            title: {
                ...commonHeading,
                fontSize: 30,
            },
        },
        MuiTypography: {
            h1: {
                ...commonHeading,
                fontSize: 57,
            },
            h2: {
                ...commonHeading,
                fontSize: 39,
            },
            h3: {
                ...commonHeading,
                fontSize: 25,
            },
            body2: {
                ...commonCode,
            },
        },
    },
});
