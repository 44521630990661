import React from "react";
import { Grid, Typography } from "@material-ui/core";


export function Feed()
{
    return <Grid
        item
        container
        direction="column"
        style={{
            // backgroundColor: "red",
        }}
    >
        {/* <Grid item>
            <Typography>Feedmeee!</Typography>
        </Grid>
        <Grid item>
            <Typography>Feedmeee!</Typography>
        </Grid>
        <Grid item>
            <Typography>Feedmeee!</Typography>
        </Grid>
        <Grid item>
            <Typography>Feedmeee!</Typography>
        </Grid> */}
    </Grid>;
}
