import React from "react";


export const ControlContext = React.createContext(false);

export function Control(props: React.Props<any>)
{
    React.useEffect(controlKey);
    const [ controlHeld, setControlState ] = React.useState(false);

    return <ControlContext.Provider value={controlHeld}>
        { props.children }
    </ControlContext.Provider>;

    function controlKey()
    {
        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("keyup", handleKeyUp);

        function handleKeyDown(event: KeyboardEvent)
        {
            if (event.keyCode === 17)
            {
                setControlState(true);
            }
        }

        function handleKeyUp(event: KeyboardEvent)
        {
            if (event.keyCode === 17)
            {
                setControlState(false);
            }
        }

        return function cleanup()
        {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("keyup", handleKeyUp);
        };
    }
}
