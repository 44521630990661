import * as _ from "lodash";
import React from "react";
import { Grid, Typography, TypographyProps, AppBar, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";


const inspirations = [
    "Far From the Maddening Crowds",
    "For the Masses",
    "Easy to Assemble",
    "Architecture & Morality",
];

interface Props
{
    title?: string;
    subtitle?: string;
    showBack?: boolean;
}

export function Header(props: Props)
{
    const inspiration = _.shuffle(inspirations)[0];

    return <Grid
        item
        container
        direction="column"
        xs={12}
        spacing={1}
    >
        <Grid
            item
            container
            style={{
                marginBottom: "38vh",
            }}
        >
            <AppBar position="fixed">
                <div
                    style={{
                        visibility: props.showBack ? "visible" : "hidden",
                    }}
                >
                    <IconButton
                        onClick={() => history.back()}
                    >
                        <ArrowBack />
                    </IconButton>
                </div>
            </AppBar>
        </Grid>

        <Grid
            item
            container
            direction="row"
            alignContent="flex-end"
            justify="flex-end"
        >
            <Heading
                variant="h1"
                style={{
                    visibility: props.subtitle ? "hidden" : "visible",
                }}
            >
                My Blog
            </Heading>
        </Grid>

        <Grid
            item
            container
            direction="row"
            alignContent="flex-end"
            justify="flex-end"
        >
            <Heading
                variant="h2"
            >
                { props.title || inspiration }
            </Heading>
        </Grid>

        <Grid
            item
            container
            direction="row"
            alignContent="flex-end"
            justify="flex-end"
            style={{
                visibility: props.subtitle ? "visible" : "hidden",
            }}
        >
            <Heading
                variant="h3"
            >
                { props.subtitle || "-" }
            </Heading>
        </Grid>


    </Grid>;
}

function Heading(props: TypographyProps)
{
    return <Typography
        display="block"
        align="right"
        { ...props }
    />;
}
