import _ from "lodash";
import React from "react";
import { CssBaseline, Grid, ThemeProvider, Typography, Tooltip } from "@material-ui/core";
import { Content } from "../Components/Content";
import { Feed } from "../Components/Feed";
import { Header } from "../Components/Header";
import { theme } from "./Theme";
import { Control } from "../Components/ControlContext";
import { graphql, useStaticQuery } from "gatsby";
import { quotes } from "../Quotes";
import { Helmet } from "react-helmet";
import { Gdpr } from "../Components/Gdpr";


interface Props extends React.ComponentProps<any>
{
    title?: string;
    subtitle?: string;
    showBack?: boolean;
}

// eslint-disable-next-line import/no-default-export
export default function WithFeed(props: Props)
{
    const songQuote = _.shuffle(quotes)[0];
    const data = useStaticQuery(query);

    return <>

        <Helmet>
            <title>My Blog</title>
            <meta name="google-site-verification" content="lgj2wHWCc-tmk2PhmZuUiH33VhML9SNcPBG5UqBY9BY" />
            <link rel="me" href="https://mastodon.social/@lostprototype" />
        </Helmet>

        <ThemeProvider theme={theme}>

            <Control>

                <CssBaseline />

                <Gdpr />

                <Grid container
                    justify="flex-end"
                    style={{
                        backgroundColor: "#dbdbdb",
                        marginBottom: "3vw",
                        paddingBottom: "1vw",
                    }}
                >
                    <Grid
                        item
                        container
                        xs={9}
                        direction="row"
                        spacing={0}
                        style={{
                            marginRight: "3vw",
                        }}
                    >
                        <Grid
                            item
                            container
                        >
                            <Header
                                title={ props.title }
                                subtitle={ props.subtitle }
                                showBack={ props.showBack }
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container justify="flex-end">

                    <Grid
                        item
                        container
                        xs={9}
                        direction="row"
                        spacing={0}
                        style={{
                            marginBottom: "3vw",
                            marginRight: "3vw",
                        }}
                    >

                        <Grid
                            item
                            container
                        >

                            <Grid
                                item
                                container
                                direction="row-reverse"
                            >
                                <Grid
                                    item
                                    container
                                    xs={true}
                                >
                                    <Content>
                                        { props.children }
                                    </Content>
                                </Grid>
                                <Grid item container xs={2}>
                                    <Feed />
                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>

                    <Grid
                        item
                        container
                        direction="row"
                        justify="flex-end"
                        style={{
                            marginBottom: "1em",
                            marginRight: "0.5em",
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: "0.7em",
                                fontStyle: "italic",
                                cursor: "pointer",
                                marginRight: "auto",
                                marginLeft: "0.5em",
                            }}
                        >
                            <a style={{ display: "none" }} rel="me" href="https://mastodon.social/@lostprototype"></a>
                            { data.environment.version }
                        </Typography>

                        <Tooltip
                            title={songQuote.source}
                            onClick={songQuote.link ? () => window.open(songQuote.link, "_blank") : null}
                        >
                            <Typography
                                style={{
                                    fontSize: "0.7em",
                                    fontStyle: "italic",
                                    cursor: "pointer",
                                }}
                            >
                                { songQuote.quote }
                            </Typography>
                        </Tooltip>

                    </Grid>
                </Grid>
            </Control>
        </ThemeProvider>
    </>;
}

const query = graphql`
    query WithFeedQuery {
        environment {
            version
        }
    }
`;
