import { Button, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";


export function Gdpr(props: React.Props<any>)
{
    return null;

    React.useEffect(syncGdprState);
    const [ accepted, setAccepted ] = React.useState(loadAcceptedState());

    return <Snackbar
        open={! accepted}
        anchorOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
    >
        <Alert
            variant="filled"
            severity="info"
            action={<Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setAccepted(true)}
            >
                Okay!
            </Button>}
        >
            I track visits to my blog, mainly to help with content!
        </Alert>
    </Snackbar>;

    function syncGdprState()
    {
        saveAcceptedState(accepted);
    }
}

function loadAcceptedState()
{
    return inBrowser()
        ? !! JSON.parse(localStorage.getItem("privacy-acknowledged"))
        : false;
}

function saveAcceptedState(state: boolean)
{
    if (! inBrowser())
    {
        return;
    }

    localStorage.setItem("privacy-acknowledged", JSON.stringify(state));
}

function inBrowser()
{
    return typeof window !== "undefined";
}
