export const quotes = [
    {
        quote: "Don't get up gentlemen, I'm only passing through.",
        source: "Bob Dylan - Things Have Changed",
        link: "https://open.spotify.com/track/6HE0fZLrv0YMd7X4uvzYot?si=f09d5494edc84b10",
    },
    {
        quote: "Lotta water under the bridge, lotta other stuff too.",
        source: "Bob Dylan - Things Have Changed",
        link: "https://open.spotify.com/track/6HE0fZLrv0YMd7X4uvzYot?si=f09d5494edc84b10",
    },
    {
        quote: "You can't scale crappy code.",
        source: "Dean Leffingwell",
    },
    {
        quote: "The progress of an artist is a continual self-sacrifice, a continual extinction of personality.",
        source: "T.S. Eliot",
        link: "https://www.laphamsquarterly.org/arts-letters/test-time",
    },
    {
        quote: "Good luck, bad luck waiting in a line.",
        source: "Pet Shop Boys - Actually - King's Cross",
        link: "https://open.spotify.com/track/4OyqSp5bgipDRltsw1Y7il?si=aESTD-YySmaMPqusNzjuaQ",
    },
    {
        quote: "This must be the place I waited years to leave.",
        source: "Pet Shop Boys - Behaviour - This Must Be The Place I Waited Years To Leave",
        link: "https://open.spotify.com/track/559mgp8lm4y0k6vG8QbZr1?si=xH1p2ILrTxyvk2JsyHqmBg",
    },
    {
        quote: "Summer sands have lost their charm, let 'em go. Autumn winds will do no harm, let 'em blow.",
        source: "Pet Shop Boys - Always",
        link: "https://open.spotify.com/track/4VJl1APyXfhfswSZTD8GdX?si=fjQxyMWsQE6d3Kx0D2Dfyg",
    },
    {
        quote: "Left to my own devices, I probably would...",
        source: "Pet Shop Boys - Introspective - Left To My Own Devices",
        link: "https://open.spotify.com/track/7KAy8CJpjoSOVgRKUT6Vvq?si=_gJfYZvCQpuSA4L0CgUEsQ",
    },
    {
        quote: "The flame that burns twice as bright burns half as long.",
        source: "Lao Tzu",
        link: "https://www.goodreads.com/quotes/90347-the-flame-that-burns-twice-as-bright-burns-half-as",
    },
    {
        quote: "I never thought that words like \"product\" could ever leave my lips -- but something happened to me somewhere that made me lose my grip...",
        source: "Barenaked Ladies - Gordon - Box Set",
        link: "https://open.spotify.com/track/5DfP8PuTDKZGIFkjUc2Ztg?si=b5cLUw9DSEi-iW-GRyhENg",
    },
    {
        quote: "You say you wanna know everything about me?  Well here you go...",
        source: "Barenaked Ladies - Gordon - Box Set",
        link: "https://open.spotify.com/track/5DfP8PuTDKZGIFkjUc2Ztg?si=b5cLUw9DSEi-iW-GRyhENg",
    },
    {
        quote: "I clutch the wire fence until my fingers bleed...",
        source: "Rush - Grace Under Pressure - Red Sector A",
        link: "https://open.spotify.com/track/5YsrGiOhGUGzW89RXrLYGV?si=52W9u32fSc2A79zJEPhl1g",
    },
    {
        quote: "...The political illiterate is so stupid that he is proud and swells his chest saying that he hates politics...",
        source: "Bertolt Brecht",
        link: "https://www.goodreads.com/quotes/541442-the-worst-illiterate-is-the-political-illiterate-he-doesn-t-hear",
    },
    {
        quote: "All sunshine makes a desert.",
        source: "Arab proverb",
        link: null,
    },
    {
        quote: "The greatest justification for travel is not self-improvement but rather performing a vanishing act, disappearing without a trace.",
        source: "Paul Theroux",
        link: null,
    },
];
