import React from "react";
import { Grid } from "@material-ui/core";


export function Content(props: React.Props<any>)
{
    return <Grid
        container
        item
        direction="column"
    >
        { props.children }
    </Grid>;
}
